<template>
    <div class="bg-white box-shadow border-r px-10">
        <div v-if="wallet.list.length > 0" class="pb-20" v-loading="loading">
            <!-- d-flex-center -->
            <div class="px-15 border-bottom">
                <div class="mr-auto-outline">
                    <div class="mr-auto-left">
                        <h4 class="mr-auto color-333">{{ $t('account.list_of_assets') }}</h4>
                        <el-button type="primary" size="mini" class="mr-10" icon="el-icon-refresh" plain @click="fatchData()">{{ $t('base.refresh') }}</el-button>
                    </div>
                    <div class="mr-auto-right">
                        <div class="mr-auto-right1">
                            <label>{{ $t('base.total') }}：</label>
                            <span class="color-p font-bold font-20" v-text="wallet.total_usd"></span>
                            <label class="ml-3">USDT</label>
                        </div>
                        <!-- 轉換多少台幣 -->
                        <!-- <div class="mr-auto-right2">
                            <span class="yue">≈</span>
                            <span class="yue_twd">{{wallet.total_twd}}</span>
                            <span class="yue_unit">TWD</span>
                        </div> -->
                        <div class="mr-auto-right3">
                            <span class="shouyi_t">{{ $t('shouyi_t') }}：</span>
                            <span class="shouyi_d shouyi_d_green" v-if="Number(wallet.income_rate>0)">{{wallet.income_rate?wallet.income_rate+'%':''}}</span>
                            <span class="shouyi_d shouyi_d_red" v-else-if="Number(wallet.income_rate<0)">{{wallet.income_rate?wallet.income_rate+'%':''}}</span>
                            <span class="shouyi_d" v-else>{{wallet.income_rate?wallet.income_rate+'%':''}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="color-gray px-20 py-15 border-bottom font-14">
                <!-- <el-col :span='4'>{{ $t('base.coin') }}</el-col>
                <el-col :span='5'>{{ $t('account.available') }}</el-col>
                <el-col :span='5'>{{ $t('account.frozen') }}</el-col>
                <el-col :span='4'>{{ $t('base.amount') }} (USD)</el-col>
                <el-col class="text-right" :span='6'>{{ $t('base.deal') }}</el-col> -->
                
                <el-col :span='4'>{{ $t('base.coin') }}</el-col>
                <el-col :span='6'>{{ $t('account.available') }}</el-col>
                <el-col :span='6'>{{ $t('account.frozen') }}</el-col>
                <el-col :span='6' style="text-align:right;">{{ $t('base.amount') }} (USD)</el-col>
            </el-row>
            <el-row v-for="(item, index) in wallet.list" :key="index" :gutter="20" type="flex" justify="center" align="middle" class="market-body px-20 py-15 font-15 border-bottom">
                <!-- <el-col :span='1'>
                    <el-avatar size="small" :src="coinLogo[item.coin] || item.logo" />
                </el-col>
                <el-col :span='3'>{{ item.coin }}</el-col>
                <el-col :span='5'>{{ item.available }}</el-col>
                <el-col :span='5'>{{ item.frozen }}</el-col>
                <el-col :span='4'>{{ item.usd }}</el-col>
                <el-col class="deal text-right" :span='6'>
                    <el-button type="success" plain size='mini' @click="$nav.push('recharge/'+item.coin)">{{ $t('account.recharge') }}</el-button>
                    <el-button type="warning" plain size='mini' @click="$nav.push('withdraw/'+item.coin)">{{ $t('account.withdraw') }}</el-button>
                </el-col> -->

                <el-col :span='1'>
                    <el-avatar size="small" :src="coinLogo[item.coin] || item.logo" />
                </el-col>
                <el-col :span='3'>{{ item.coin }}</el-col>
                <el-col :span='6'>{{ item.available }}</el-col>
                <el-col :span='6'>{{ item.frozen }}</el-col>
                <el-col :span='6' style="text-align:right;">{{ item.usd }}</el-col>
            </el-row>
        </div>
        <div v-else v-loading="true" element-loading-background="#00000000" class="p-50" />
        <div class="pb-20" v-loading="loadingRecord">
            <div class="d-flex-center px-15 border-bottom">
                <h4 class="mr-auto color-333">{{ $t('account.available_log') }}</h4>
                <!-- <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord()">{{ $t('base.refresh') }}</el-button> -->
                <el-button type="primary" size="mini" icon="el-icon-refresh" plain @click="fatchRecord(true)">{{ $t('base.refresh') }}</el-button>
            </div>
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="color-gray px-20 py-15 border-bottom font-14">
                <el-col :span='3'>{{ $t('base.coin') }}</el-col>
                <el-col :span='6'>{{ $t('base.amount') }}</el-col>
                <el-col :span='7'>{{ $t('account.event') }}</el-col>
                <el-col :span='8' class="text-right">{{ $t('account.time') }}</el-col>
            </el-row>
            <el-row v-for="(item, index) in list.data" :key="index" :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-15 border-bottom font-14">
                <el-col :span='3'>{{ item.coin }}</el-col>
                <el-col :span='6'>{{ item.amount }}</el-col>
                <el-col :span='7'>{{ listType[item.type] }}</el-col>
                <el-col :span='8' class="text-right">{{ item.create_time }}</el-col>
            </el-row>
            <div class="d-flex-center pt-20">
                <!-- <el-pagination
                    :hide-on-single-page="true"
                    background
                    layout="prev, pager, next"
                    :page-size="list.per_page"
                    :current-page="list.current_page"
                    :total="list.total"
                    @current-change="onPage"
                /> -->
                <el-pagination
                    :hide-on-single-page="true"
                    background
                    layout="prev, pager, next"
                    :page-size="orderQuery.limit"
                    :current-page="current_page"
                    :total="list.total"
                    @current-change="onPage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import coinLogo from '@/assets/coin/logo'
export default {
    data() {
        return {
            coinLogo,
            loading: false,
            wallet: {
                list: [],
                total_usd: '0.00'
            },
            query: { ...this.$route.query },
            loadingRecord: false,
            list: {
                per_page: 1,
                current_page: 1,
                total: 0,
                data: []
            },
            // 新增傳參，pc與移動統一
            orderQuery: {
                limit: 10,
                last_id: -1,
                finished: false,
            },
            current_page:1,

            // 新增
            // 25=转出至合约账户
            // 26=合约账户转入
            listType: [
                this.$t('account.event_type.recharge'),
                this.$t('account.event_type.take'),
                this.$t('account.event_type.withdraw'),
                this.$t('account.event_type.withdraw_fail'),
                this.$t('account.event_type.trade_buy'),
                this.$t('account.event_type.trade_sell'),
                this.$t('account.event_type.trade_success'),
                this.$t('account.event_type.trade_cancel'),
                this.$t('account.event_type.subscribe'),
                this.$t('account.event_type.contract_buy'),
                this.$t('account.event_type.contract_back'),
                this.$t('account.event_type.contract_win'),
                this.$t('account.event_type.frozen'),
                this.$t('account.event_type.thaw'),
                this.$t('account.event_type.fund_buy'),
                this.$t('account.event_type.fund_buy_fail'),
                this.$t('account.event_type.fund_back'),
                this.$t('account.event_type.fund_income'),
                this.$t('account.event_type.cfl1ua'),
                this.$t('account.event_type.cfl1u'),
                this.$t('account.event_type.cfl2u'),
                this.$t('account.event_type.cfl3u'),
                this.$t('account.event_type.exchange'),
                this.$t('account.event_type.exchange'),
                this.$t('account.event_type.exchange'),

                this.$t('account.event_type.new25'),
                this.$t('account.event_type.new26'),
                this.$t('account.event_type.new27'),
                this.$t('account.event_type.new28'),
                this.$t('account.event_type.new29'),
                this.$t('account.event_type.new30'),
                this.$t('account.event_type.new31'),
            ]
        }
    },
    created: function() {
        this.fatchData()
        this.fatchRecord()
    },
    methods: {
        fatchData: async function () {
            this.loading = true
            // const { data } = await this.$request.get('wallet')
            const { data } = await this.$request.post('v1/symbol/getUserWalletList')
            this.wallet = data
            this.loading = false
        },
        // fatchRecord: async function () {
        //     try {
        //         this.loadingRecord = true
        //         const { data } = await this.$request.post('wallet/log_pc', this.query)
        //         this.list = data
        //         this.loadingRecord = false
        //     } catch (error) {
        //         this.loadingRecord = false
        //     }
        // },

        // 更換接口，pc與移動統一 
        fatchRecord: async function (refresh = false) {
            if (refresh) {
                // 回到第一頁
                this.orderQuery = {
                    limit: 10,
                    last_id: -1,
                    finished: false,
                };
                this.current_page = 1
            }
            let postData = {}
            postData.query = this.orderQuery
            if (this.orderQuery.finished) return;

            try {
                this.loadingRecord = true
                // const { data } = await this.$request.post('wallet/log_pc', this.query)
                // this.list = data
                const { data } = await this.$request.post('v1/symbol/getCoinLog', postData)
                this.orderQuery = data.query; // 更新分頁相關傳參
                this.list = data
                this.loadingRecord = false
            } catch (error) {
                this.loadingRecord = false
            }
        },
        onSearch() {
            this.fatchRecord()
            this.$router.replace({
                query: this.query
            })
        },
        onPage(page) {
            // this.query.page = page || 1
            this.current_page = page
            // this.onSearch()
            this.fatchRecord()
        }
    }
}
</script>
<style lang="scss" scoped>
//
.mr-auto-outline{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.mr-auto-left{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.mr-auto-right{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.mr-auto-left>.mr-auto{
    margin-right: 10px;
}
.mr-auto-right>div{
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 30px;
}
.mr-auto-right .mr-auto-right2{
    margin-left: 0;
}
.mr-auto-right .mr-auto-right2 .yue{
    margin: 0 5px;
}
.mr-auto-right2 .yue_unit{
    margin-left: 3px;
    font-size: 14px;
}
.shouyi_d_green{
    color: #67c23a;
}
.shouyi_d_red{
    color: #f56c6c;
}
.shouyi_d{
    font-size: 18px;
    font-weight: bold;
}
.mr-auto-right1 .ml-3{
    font-size: 14px;
}
</style>